<template>
  <div class="Orderlist">
    <div class="top-title">
      <div class="top-text">经理收益</div>
      
        <el-form ref="form" label-width="100px">
          <div class="top-box">
 
          <el-form-item label="年月筛选">
              <el-date-picker
                    v-model="seeks.b"  type="month"
                    format="yyyy 年 MM 月" value-format="yyyy-MM"
                    placeholder="选择月">
                  </el-date-picker>
           </el-form-item>

            <div>
              <el-button
                type="primary"
                class="biy-icon" icon="el-icon-check" @click="seek()"
                >搜索</el-button>
            <el-button
                type="warning"
                class="biy-icon ite" icon="" @click="daochus()"
                >导出excel</el-button>

            </div>

            </div>
        </el-form>
      
    </div>


    <!-- 选择列表  表格-->
    <div class="table-form">
      <el-table :data="list" row-class-name="tableRowStyle" v-loading="loading">
        <el-table-column prop="wk_truename" label="经理"> </el-table-column>
        <el-table-column prop="bu_mobile" label="经理手机号"> </el-table-column>
        <el-table-column prop="exwk_truename" label="经理外挂"> </el-table-column>
        <el-table-column prop="exbu_mobile" label="经理外挂手机号"> </el-table-column>
        <el-table-column prop="precommission_month_total" label="本月预估"> </el-table-column>
        <el-table-column prop="commission_premonth_total" label="上月结算"> </el-table-column>
        <el-table-column prop="teamprecommission_month_total" label="平台本月预估"> </el-table-column>
        <el-table-column prop="teamcommission_premonth_total" label="平台上月结算"> </el-table-column>

      </el-table>
      <!-- 分页器 -->
      <div class="block" style="margin-top: 15px">
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[30, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>

      </div>
    </div>
  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
export default {
  data() {
    return {

      // 分页
      currentPage: 1, // 当前页码
      total: 10,
      pageSize: 30, // 每页的数据条数
      loading: this.$common.loading,

      // 下拉框
      seeks: {},
      classactive:0,

      list:[],
      lists:[],
      // 是否导出
      daochu:false,
    };
  },
  created() {
     this.getSong();
  },
  mounted() {

  },

  methods: {


    // //查询接口
      async getSong(){
				let res = await apiurl.fetchPageData({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        bizCatalog:'WorkerEntity',
      handleMode:'managerRevenueList',
      querydate:this.seeks.b,
      exportExcel:this.daochu,
        query:{
          
        }, })
   
        if(this.$common.verifyAjaxResult(res,false)==false) return;
           this.list = res.Result.pageData
           this.total = res.Result.count
           this.loading = false
      },


    //导出确认弹窗
       daochus(row){
          this.daochu = true
          this.currentPage = 1
          this.$confirm('是否确认导出', '消息提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消操作'
        }).then(() => {
          this.daochuss()
          }).catch(action => {
            console.log("取消");
          })
      },

    // 导出
     daochuss(){
       var data=[];
        data["FOREND_TOKEN"]=this.$common.baseGetToken();//统一传递一下前端token 
        data["loginName"]=this.$common.baseGetuSername();//登录账号 
        data["loginType"]=this.$common.baseGetSeparate();//1平台登录 2商家登录        
        data["bizCatalog"]="WorkerEntity";
        data["handleMode"]="managerRevenueList";
        data["exportExcel"]= this.daochu;
        data["pageIndex"]=this.currentPage;   
        data["pageSize"]=this.pageSize;   
        data["querydate"]=this.seeks.b,//筛选年月     
        data["query"]={  };    
        if(this.daochu == true) return this.$common.postExcelFile(data, 
      'https://api.yxp1419153.com/ajaxEntryCenter.php?WsAjaxPrj=YXP&WsAjaxBiz=Plater&WsAjaxAction=fetchPageData');
      },




    // 搜索栏
    seek(){
      this.currentPage = 1;
        this.getSong();
    },




    // 分页 每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.pageSize = val;
      this.getSong();
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSong();
    },
  },
  computed: {},
};
</script>


<style  scoped>
.table-tap{
  width: 98%;
  margin: 0 auto;
  padding: 30px 0;
  border-bottom: 2px solid rgb(255, 187, 0);
}
.table-tap ul{
  display: flex;
  justify-content: left;
}
.table-tap ul li{
  width: 150px;
  text-align: center;
}
.table-tap ul li span{
font-size: 18px;
cursor: pointer;
transition: all .3s;
}
.table-tap ul li span.active{
  color: rgb(255, 187, 0);
  font-size: 16px;
}
@import "../../assets/css/liststyle.css";
</style>